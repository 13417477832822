import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
//'Okey','Hearts',
  themes =  ['GinRummy','Tonk','Spades','Belote','Mindi','Euchre',"Uno"];
  
  public currentgame : string;

  userMenu = [ { title: 'Profile' }, { title: 'Log out' } ];

  constructor(private sidebarService: NbSidebarService,
              private router: Router,private authService: NbAuthService,
              private layoutService: LayoutService) {
  }

  ngOnInit() {
    this.currentgame = (localStorage.getItem('game') != undefined) ? localStorage.getItem('game') : "GinRummy" ;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeGame(themeName: any) {
    localStorage.setItem('game',themeName);
    this.currentgame = themeName;
    window.location.reload()
    //currentgamelink = themeName.value;
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    // this.menuService.navigateHome();
    this.router.navigate(['/pages/dashboard'])
    return false;
  }
  navigateLogin(){
    localStorage.removeItem('auth_app_token')
    this.router.navigate(['/auth/login'])
    return false;
  }
}
